((w, d) => {
  w.semaphore = w.semaphore || [];
  w.ketch = (...args: unknown[]) => {
    w.semaphore.push(args);
  };
  const e = d.createElement('script');
  e.type = 'text/javascript';
  e.src = 'https://global.ketchcdn.com/web/v3/config/lendingtree/website_smart_tag/boot.js';
  e.defer = e.async = true;
  d.getElementsByTagName('head')[0].appendChild(e);
})(window, document);
